<template>
  <!-- 线上课程 -->
  <div class="con_contain">
    <div class="mainpage">
      <!-- <div class="head">
        <div class="searchcontain">
          <input class="elinput" placeholder="请输入直播名称" v-model="queryParams.keyword" type="text"
            @keyup.enter="searchbt" />

          <div class="searchbt el-icon-search" @click="searchbt"><span>搜索</span></div>
        </div>
      </div>-->
      <div class="image-contain">
        <!--近期直播start-->
        <div class="topCont">
          <div class="list-title-cont">
            <span>
              <img :src="liveBro" alt />
            </span>近期直播
          </div>
          <el-button class="dateBtn" round @click="showDate">
            <span>
              <svg-icon icon-class="calendar" />
            </span>直播日历
          </el-button>
        </div>
        <div class="timeLineCont">
          <el-divider class="lineCont"></el-divider>
          <div class="playCont" v-for="(v, i) in videoList" :key="i">
            <div class="svgBg">
              <svg width="20" height="20" viewBox="-50 -50 300 300">
                <polygon class="triangle" :class="!i ? 'active' : ''" stroke-linejoin="round"
                  points="100,0 0,200 200,200" />
              </svg>
            </div>
            <div class="timeText" :class="!i ? 'active' : ''">
              {{ v.date }}
              <span v-if="!i">今日</span>
            </div>
            <div class="list-li">
              <div class="list-li-arrow-wrap">
                <div class="list-li-arrow"></div>
              </div>
              <div class="list-li-panel" :class="!v.list.length ? 'empty-cont' : ''">
                <div class="block" v-if="v.list.length">
                  <el-timeline>
                    <el-timeline-item v-for="(el, inx) in v.list" :key="inx" size="large"
                      :timestamp="el.start_time | dataformat" placement="top">
                      <div class="list-li-select">
                        <div class="list-imgCont">
                          <img :src="el.cover" alt />
                        </div>
                        <div class="list-cont">
                          <div class="list-title" v-if="el.title.length < 42">{{ el.title }}</div>
                          <el-tooltip placement="top" effect="light" v-else>
                            <div slot="content">{{ el.title }}</div>
                            <div class="list-title">{{ el.title }}</div>
                          </el-tooltip>
                          <div class="info-entrance">
                            <div v-if="el.live_user_info.username.length < 13">{{ el.live_user_info.username }}</div>
                            <el-tooltip placement="top" effect="light" v-else>
                              <div slot="content">{{ el.live_user_info.username }}</div>
                              <div>{{ el.live_user_info.username }}</div>
                            </el-tooltip>
                            <el-button @click="todetail(el)">进入直播</el-button>
                          </div>
                        </div>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <el-empty :image-size="60" v-else></el-empty>
              </div>
            </div>
          </div>
        </div>
        <!--近期直播end-->
        <!--直播回放start-->
        <div class="playbackCont">
          <div class="list-title-cont">
            <span>
              <img :src="playback" alt />
            </span>直播回放
          </div>
          <div class="image-item" v-if="courselist && courselist.length > 0">
            <div class="image-item-item" @click="todetail(item)" v-for="(item, index) in courselist" :key="index">
              <div class="image-item-item-li">
                <div class="card-contain">
                  <div class="card-label" v-if="item.is_recommend">{{ labelMsg }}</div>
                  <div class="card-mask-cont">
                    <div class="card-mask">
                      <div class="el-icon-view viewCont">
                        <span>{{ item.record_view_count || viewNum }}人观看</span>
                      </div>
                      <div>{{ item.viewTime || viewTime }}</div>
                    </div>
                  </div>
                  <img class="elimage" :src="item.cover || cover" />
                </div>
                <div class="card-title">
                  <div class="title">{{ item.title }}</div>
                  <div class="describe">
                    <div class="describe-info">
                      <span>{{ item.live_user_info.username }}</span>
                    </div>
                    <div class="describe-like">
                      <span>
                        <svg-icon icon-class="fabulous" />
                      </span>
                      {{ item.likes_count }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elpagination isLivePagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="36"
          :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
      </div>
      <Customdialog ref="customdialog" :showclose="true" title="直播日历" :width="liveWidth" type="table" class="dateDialog">
        <div slot="dialogbody" class="dialogbody">
          <div class="dialogCalendar">
            <el-calendar v-model="dateValue">
              <template slot="dateCell" slot-scope="{ data }">
                <span>{{ data.day.split('-').slice(2).join('-') }}</span>
                <template v-if="calendarList.length">
                  <div class="isTag" v-if="showTag(data.day)"></div>
                  <!-- <div class="isTag"></div> -->
                </template>
              </template>
            </el-calendar>
          </div>
          <div class="dialogTimeLine">
            <div class="timelineTitle">直播信息</div>
            <div class="timeline-cont" :class="!currentList.length ? 'empty-cont' : ''">
              <div v-if="currentList.length">
                <el-timeline>
                  <el-timeline-item v-for="(item, index) in currentList" :key="index" size="large"
                    :timestamp="item.start_time | dataformat" placement="top">
                    <div class="list-li-select">
                      <div class="list-imgCont">
                        <img :src="item.cover" alt />
                      </div>
                      <div class="list-cont">
                        <div class="list-title" v-if="item.title.length < 42">{{ item.title }}</div>
                        <el-tooltip placement="top" effect="light" v-else>
                          <div slot="content">{{ item.title }}</div>
                          <div class="list-title">{{ item.title }}</div>
                        </el-tooltip>
                        <div class="info-entrance">
                          <div v-if="item.live_user_info.username.length < 13">{{ item.live_user_info.username }}</div>
                          <el-tooltip placement="top" effect="light" v-else>
                            <div slot="content">{{ item.live_user_info.username }}</div>
                            <div>{{ item.live_user_info.username }}</div>
                          </el-tooltip>
                          <el-button @click="todetail(item)">进入直播</el-button>
                        </div>
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
              </div>
              <el-empty :image-size="60" v-else></el-empty>
            </div>
          </div>
        </div>
      </Customdialog>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs"
import { liveList, getRecentList } from "@/api/course"
import Customdialog from "@/components/customdialog.vue"
export default {
  name: "mainpage",
  components: { Customdialog },
  data() {
    return {
      courseinfo: "",
      queryParams: {
        keyword: "",
        college_id: "",
        subject_config_id: "",
        sort_type: 1,
        per_page: 36,
        page: 1,
      },
      courselist: [],
      collegelist: [],
      subjectclassification: [],
      total: 0,
      cover: require("@/assets/zhibo.png"),
      liveBro: require("@/assets/svg/live_broadcast.png"),
      playback: require("@/assets/svg/playback.png"),
      videoList: [],
      calendarList: [],
      labelMsg: '推荐',
      viewNum: 0,
      viewTime: '00:00:00',
      dateValue: '',
      isMobile: '',
      liveWidth: '',
      lastMonthday: '',  //切换月份的总天数
      currentList: [],   //点击日期的直播记录
    }
  },
  computed: {},
  filters: {
    dataformat(value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    }
  },
  watch: {
    dateValue: {
      handler(newVal) {
        if (newVal === "") return false
        this.getInitRecentList('switch')
      },
      immediate: true,
      deep: true
    },
  },
  created() {
    this.getInitRecentList()
    this.liveList()
    if (this._isMobile()) {
      this.isMobile = 0
      this.liveWidth = 100 +'%'
    } else {
      this.isMobile = 1
      this.liveWidth = 70 + '%'
    }
  },
  methods: {
    // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    searchbt() {
      this.getInitRecentList()
      this.queryParams.page = 1
      this.liveList()
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val
      this.liveList()
    },
    academybtclick(id) {
      this.queryParams.college_id = id
      this.queryParams.page = 1
      this.liveList()
    },
    subjectbtclick(id) {
      this.queryParams.subject_config_id = id
      this.queryParams.page = 1
      this.liveList()
    },

    sortbtclick(id) {
      this.queryParams.sort_type = id
      this.queryParams.page = 1
      this.liveList()
    },
    todetail(item) {
      this.$router.push({
        path:
          "/home/livebroadcast/courselearning?sn=" +
          item.sn,
      })
      document.title = item.title
    },
    timeFormat(data) { //补齐时间，返回格式为:yyyy.mm.dd
      let date = ''
      if (!data) {
        date = new Date()
      } else {
        date = this.dateValue
      }
      let strArr = date.toLocaleDateString().split('/')
      let timer = ''
      strArr.forEach((item, index) => {
        if (!index) {
          timer = item
        } else {
          if (parseInt(item) < 10) {
            item = '.0' + item
          } else {
            item = '.' + item
          }
          timer = timer + item
        }
      })
      return timer
    },
    getNextDate(day = 1, format = "{y}-{m}-{d}") { //当前日期后4天
      const nDate = new Date()
      nDate.setDate(nDate.getDate() + day)
      const formatObj = {
        y: nDate.getFullYear(),
        m: nDate.getMonth() + 1,
        d: nDate.getDate(),
      }
      return format.replace(/{([ymd])+}/g, (result, key) => {
        const value = formatObj[key]
        return value.toString().padStart(2, "0")
      })
    },
    getInitRecentList(str) {
      if (str === 'opCalendar') { //当月的直播记录查询
        let resDate = parseInt(this.timeFormat(1).replaceAll('.', ''))
        let start_time = parseInt(resDate.toString().substring(0, 6) + '01')
        let end_time = parseInt(resDate.toString().substring(0, 6) + this.lastMonthday.toString())
        let params = {
          start_date: start_time,
          end_date: end_time
        }
        getRecentList(params).then(res => {
          this.calendarList = []
          if (res.code === 0) {
            res.data.forEach(item => {
              this.calendarList.push({
                date: item.date,
                list: item.list || []
              })
            })
          }
        })
      } else if (str === 'switch') {
        this.currentList = []
        let start_time = parseInt(this.timeFormat(1).replaceAll('.', ''))
        let end_time = start_time
        let params = {
          start_date: start_time,
          end_date: end_time
        }
        getRecentList(params).then(res => {
          if (res.code === 0) {
            this.currentList = res.data[0].list || []
          }
        })
      } else {  //初始化近4天的直播记录
        let start_time = parseInt(this.timeFormat(0).replaceAll('.', ''))
        let end_time = parseInt(this.getNextDate(3, "{y}{m}{d}"))
        let params = {
          start_date: start_time,
          end_date: end_time
        }
        getRecentList(params).then(res => {
          if (res.code === 0) {
            res.data.forEach(item => {
              this.videoList.push({
                date: item.date,
                list: item.list || []
              })
            })
          }
        })
      }
    },
    liveList() {
      this.courselist = []
      liveList(this.queryParams)
        .then((response) => {
          //console.log(response, "response");
          if (response.data.data && response.data.data.length >= 0) {
            this.total = response.data.total
            this.courselist = response.data.data
          }
        })
        .catch((error) => {
          //console.log(error);
        })
    },
    showDate() {
      this.dateValue = new Date()
      this.$refs.customdialog.dialogopenbt()
      this.$nextTick(() => { //给上月、今天、下月添加点击事件
        // 点击前一个月
        let prevBtn = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(1)"
        )
        prevBtn.addEventListener("click", () => {
          this.switchDate(this.dateValue)
        })
        let dayBtn = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(2)"
        )
        dayBtn.addEventListener("click", () => {
          this.switchDate(this.dateValue)
        })
        let nextBtn = document.querySelector(
          ".el-calendar__button-group .el-button-group>button:nth-child(3)"
        )
        nextBtn.addEventListener("click", () => {
          this.switchDate(this.dateValue)
        })
      })
      this.switchDate(new Date())
    },
    switchDate(dateVal) { //切换月份的总天数
      let date = dateVal.toLocaleDateString().split('/')
      let tempDate = new Date(date[0], date[1], 0)
      this.lastMonthday = tempDate.getDate()
      this.getInitRecentList('opCalendar')
    },
    showTag(value) { //遍历日历，有直播记录的打标记
      let arr = this.calendarList
      for (let i = 0; i < arr.length; i++) {
        let ele = arr[i]
        let resStr = value.replaceAll('-', '.')
        if (ele.date === resStr && ele.list.length) {
          return true
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .con_contain {
    margin: 0px 10px;
    margin-top: 130px;
    .mainpage {
      background: #ffffff;

      // width: 1760px;
      .head {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 43px;
            border-radius: 25px;
            padding-left: 20px;
            border: none; //1px solid #F3F4F9;
            background: #f3f4f9;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 43px;
            background: #2bc17b;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 43px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }

            span {
              padding-left: 8px;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding: 30px 10px 5px 30px;

        .titlecategory-item {
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 30px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
            }
          }
        }
      }

      .image-contain {
        margin-top: 45px;
        font-family: PingFang SC;

        .image-item {
          margin-bottom: 50px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .image-item-item {
            padding: 0px 12.5px;
            min-width: calc(20% - 25px);
            margin-bottom: 25px;

            .image-item-item-li {
              cursor: pointer;
              height: 309px;
              background: #ffffff;
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
              border-radius: 0px 0px 3px 3px;
            }

            .card-contain {
              width: 100%;
              height: 235px;
              overflow: hidden;
              position: relative;

              .card-label {
                position: absolute;
                width: 60px;
                height: 27px;
                background: #2bc17b;
                border-radius: 0px 5px 0px 14px;
                z-index: 5;
                top: 0px;
                right: 0px;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                line-height: 27px;
                text-align: center;
              }

              .card-mask-cont {
                width: calc(100% - 20px);
                height: 25px;
                background: #000000;
                opacity: 0.4;
                position: absolute;
                bottom: 0px;
                z-index: 8;
                padding: 0px 10px;

                .card-mask {
                  width: 100%;
                  height: 100%;
                  color: #fff;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 12px;
                  font-weight: 500;

                  .viewCont {
                    span {
                      padding-left: 6px;
                    }
                  }
                }
              }

              .elimage {
                position: absolute;
                z-index: 4;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                transform: translate3d(-50%, -50%, 0);
              }

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1) translate3d(-46%, -46%, 0);
                transform: scale(1.1) translate3d(-46%, -46%, 0);
              }
            }

            .card-title {
              height: calc(100% - 255px);
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              padding: 10px;

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                height: 30px;
                line-height: 30px;
              }

              .describe {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;

                .describe-info {
                  span {
                    padding-right: 8px;
                    margin-right: 8px;
                    border-right: 2px solid rgba(0, 0, 0, 0.2);
                  }
                }

                .describe-like {
                  font-weight: 500;

                  span {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin-right: 6px;
                  }
                }
              }
            }

            .card-footer {
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .icon-title {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 400;
                color: #666666;
              }

              .icon-text {
                background-image: url(~@/assets/矩形图标.png);
                width: 108px;
                height: 24px;
                text-align: center;
                color: #288add;
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }

      .topCont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .list-title-cont {
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }

        .dateBtn {
          span {
            width: 17px;
            height: 17px;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }

      .timeLineCont {
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        position: relative;
        .playCont {
          margin-top: 30px;
        }

        .lineCont {
          position: absolute;
          top: 5px;
          height: 2px;
          background: #ebeef5;
        }

        .svgBg {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: rgba(248, 248, 251, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50%;
          transform: translateX(-27px);

          svg {
            transform: rotate(-26deg);
          }

          .triangle {
            fill: rgba(222, 222, 222, 1);
            stroke: rgba(222, 222, 222, 1);
            stroke-width: 100;
          }

          .triangle.active {
            fill: rgba(43, 193, 123, 1);
            stroke: rgba(43, 193, 123, 1);
          }
        }

        .timeText {
          text-align: center;
          height: 26px;
          font-size: 14px;
          font-weight: 500;
          line-height: 40px;
          // padding: 6px 0px;
          color: #666666;
        }

        .timeText.active {
          font-size: 16px;
          color: rgba(43, 193, 123, 1);
        }

        .list-li {
          .list-li-arrow-wrap {
            height: 24px;
            overflow: hidden;
            position: relative;
          }

          .list-li-arrow {
            width: 20px;
            height: 20px;
            background: #ffffff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            position: absolute;
            left: 50%;
            transform: rotate(45deg) translateX(-12px);
            margin-top: 24px;
          }

          .list-li-panel {
            width: 389px;
            min-height: 154px;
            max-height: 423px;
            border-radius: 3px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            overflow-y: auto;
          }

          .empty-cont {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .el-timeline-item {
            padding-bottom: 20px;
          }
        }
      }

      .playbackCont {
        margin-top: 40px;

        .list-title-cont {
          margin-bottom: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }
      }

      .dialogTimeLine {
        min-width: 370px;
        padding: 30px 30px 30px 0px;

        .timelineTitle {
          font-size: 20px;
          font-weight: 400;
        }

        .timeline-cont {
          overflow-y: auto;
          min-height: 160px;
          max-height: 540px;
          margin-top: 16px;
        }

        .empty-cont {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .list-li-panel .list-li-select,
      .dialogTimeLine .list-li-select {
        display: flex;
        justify-content: space-between;

        .list-imgCont {
          min-width: 140px;
          height: 96px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-cont {
          position: relative;
          padding-left: 10px;
          width: calc(100% - 130px);
          height: 96px;

          .list-title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            height: calc(100% - 30px);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
        }

        .info-entrance {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          height: 30px;

          div {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            width: calc(100% - 80px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          button {
            width: 58px;
            height: 22px;
            border: 1px solid #2bc17b;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #2bc17b;
            padding: 0px;
          }

          button:hover {
            background: rgba($color: #2bc17b, $alpha: 0.1);
          }
        }
      }

      .dialogbody {
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        padding: 0 !important;
        .dialogCalendar {
          padding: 0px;
        }
        .dialogTimeLine{
          padding: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .con_contain {
    margin: 0px 10px;

    .mainpage {
      background: #ffffff;

      // width: 1760px;
      .head {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 43px;
            border-radius: 25px;
            padding-left: 20px;
            border: none; //1px solid #F3F4F9;
            background: #f3f4f9;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 43px;
            background: #2bc17b;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 43px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }

            span {
              padding-left: 8px;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding: 30px 10px 5px 30px;

        .titlecategory-item {
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 30px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
            }
          }
        }
      }

      .image-contain {
        margin-top: 80px;
        .image-item {
          margin-bottom: 50px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .image-item-item {
            padding: 0px 12.5px;
            min-width: calc(20% - 25px);
            margin-bottom: 25px;

            .image-item-item-li {
              cursor: pointer;
              height: 309px;
              background: #ffffff;
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
              border-radius: 0px 0px 3px 3px;
            }

            .card-contain {
              width: 100%;
              height: 235px;
              overflow: hidden;
              position: relative;

              .card-label {
                position: absolute;
                width: 60px;
                height: 27px;
                background: #2bc17b;
                border-radius: 0px 5px 0px 14px;
                z-index: 5;
                top: 0px;
                right: 0px;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                line-height: 27px;
                text-align: center;
              }

              .card-mask-cont {
                width: calc(100% - 20px);
                height: 25px;
                background: #000000;
                opacity: 0.4;
                position: absolute;
                bottom: 0px;
                z-index: 8;
                padding: 0px 10px;

                .card-mask {
                  width: 100%;
                  height: 100%;
                  color: #fff;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 12px;
                  font-weight: 500;

                  .viewCont {
                    span {
                      padding-left: 6px;
                    }
                  }
                }
              }

              .elimage {
                position: absolute;
                z-index: 4;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                transform: translate3d(-50%, -50%, 0);
              }

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1) translate3d(-46%, -46%, 0);
                transform: scale(1.1) translate3d(-46%, -46%, 0);
              }
            }

            .card-title {
              height: calc(100% - 255px);
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              padding: 10px;

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                height: 30px;
                line-height: 30px;
              }

              .describe {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;

                .describe-info {
                  span {
                    padding-right: 8px;
                    margin-right: 8px;
                    border-right: 2px solid rgba(0, 0, 0, 0.2);
                  }
                }

                .describe-like {
                  font-weight: 500;

                  span {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin-right: 6px;
                  }
                }
              }
            }

            .card-footer {
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .icon-title {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 400;
                color: #666666;
              }

              .icon-text {
                background-image: url(~@/assets/矩形图标.png);
                width: 108px;
                height: 24px;
                text-align: center;
                color: #288add;
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }

      .topCont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .list-title-cont {
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }

        .dateBtn {
          span {
            width: 17px;
            height: 17px;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }

      .timeLineCont {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        .lineCont {
          position: absolute;
          top: 5px;
          height: 2px;
          background: #ebeef5;
        }

        .svgBg {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: rgba(248, 248, 251, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50%;
          transform: translateX(-27px);

          svg {
            transform: rotate(-26deg);
          }

          .triangle {
            fill: rgba(222, 222, 222, 1);
            stroke: rgba(222, 222, 222, 1);
            stroke-width: 100;
          }

          .triangle.active {
            fill: rgba(43, 193, 123, 1);
            stroke: rgba(43, 193, 123, 1);
          }
        }

        .timeText {
          text-align: center;
          height: 26px;
          font-size: 14px;
          font-weight: 500;
          line-height: 40px;
          // padding: 6px 0px;
          color: #666666;
        }

        .timeText.active {
          font-size: 16px;
          color: rgba(43, 193, 123, 1);
        }

        .list-li {
          .list-li-arrow-wrap {
            height: 24px;
            overflow: hidden;
            position: relative;
          }

          .list-li-arrow {
            width: 20px;
            height: 20px;
            background: #ffffff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            position: absolute;
            left: 50%;
            transform: rotate(45deg) translateX(-12px);
            margin-top: 24px;
          }

          .list-li-panel {
            width: 389px;
            min-height: 154px;
            max-height: 423px;
            border-radius: 3px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            overflow-y: auto;
          }

          .empty-cont {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .el-timeline-item {
            padding-bottom: 20px;
          }
        }
      }

      .playbackCont {
        margin-top: 40px;

        .list-title-cont {
          margin-bottom: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }
      }

      .dialogTimeLine {
        min-width: 370px;
        padding: 30px 30px 30px 0px;

        .timelineTitle {
          font-size: 20px;
          font-weight: 400;
        }

        .timeline-cont {
          overflow-y: auto;
          min-height: 160px;
          max-height: 540px;
          margin-top: 16px;
        }

        .empty-cont {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .list-li-panel .list-li-select,
      .dialogTimeLine .list-li-select {
        display: flex;
        justify-content: space-between;

        .list-imgCont {
          min-width: 140px;
          height: 96px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-cont {
          position: relative;
          padding-left: 10px;
          width: calc(100% - 130px);
          height: 96px;

          .list-title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            height: calc(100% - 30px);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
        }

        .info-entrance {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          height: 30px;

          div {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            width: calc(100% - 80px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          button {
            width: 58px;
            height: 22px;
            border: 1px solid #2bc17b;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #2bc17b;
            padding: 0px;
          }

          button:hover {
            background: rgba($color: #2bc17b, $alpha: 0.1);
          }
        }
      }

      .dialogbody {
        // display: flex;
        // justify-content: space-between;
        // align-items: flex-start;
        padding: 0 !important;
        .dialogCalendar {
          padding: 0px;
        }
        .dialogTimeLine{
          padding: 10px;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .con_contain {
    margin: 0px 60px;

    .mainpage {
      background: #ffffff;

      // width: 1760px;
      .head {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .head-title {
          font-size: 20px;

          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 43px;
            border-radius: 25px;
            padding-left: 20px;
            border: none; //1px solid #F3F4F9;
            background: #f3f4f9;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 43px;
            background: #2bc17b;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 43px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }

            span {
              padding-left: 8px;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        min-height: 150px;
        background: #fbfbfb;
        border-radius: 4px;
        padding: 30px 10px 5px 30px;

        .titlecategory-item {
          margin-bottom: 30px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;
            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 30px;
              font-size: 14px;
              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
            }
          }
        }
      }

      .image-contain {
        margin-top: 45px;
        font-family: PingFang SC;

        .image-item {
          margin-bottom: 50px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;

          .image-item-item {
            padding: 0px 12.5px;
            min-width: calc(20% - 25px);
            margin-bottom: 25px;

            .image-item-item-li {
              cursor: pointer;
              height: 309px;
              background: #ffffff;
              box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
              border-radius: 0px 0px 3px 3px;
            }

            .card-contain {
              width: 100%;
              height: 235px;
              overflow: hidden;
              position: relative;

              .card-label {
                position: absolute;
                width: 60px;
                height: 27px;
                background: #2bc17b;
                border-radius: 0px 5px 0px 14px;
                z-index: 5;
                top: 0px;
                right: 0px;
                font-size: 14px;
                font-weight: bold;
                color: #ffffff;
                line-height: 27px;
                text-align: center;
              }

              .card-mask-cont {
                width: calc(100% - 20px);
                height: 25px;
                background: #000000;
                opacity: 0.4;
                position: absolute;
                bottom: 0px;
                z-index: 8;
                padding: 0px 10px;

                .card-mask {
                  width: 100%;
                  height: 100%;
                  color: #fff;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 12px;
                  font-weight: 500;

                  .viewCont {
                    span {
                      padding-left: 6px;
                    }
                  }
                }
              }

              .elimage {
                position: absolute;
                z-index: 4;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                transform: translate3d(-50%, -50%, 0);
              }

              .elimage {
                display: inline-block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1) translate3d(-46%, -46%, 0);
                transform: scale(1.1) translate3d(-46%, -46%, 0);
              }
            }

            .card-title {
              height: calc(100% - 255px);
              font-size: 14px;
              font-weight: 500;
              color: #222222;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
              padding: 10px;

              .title {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                height: 30px;
                line-height: 30px;
              }

              .describe {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 22px;
                line-height: 22px;
                font-size: 14px;
                font-weight: 400;
                color: #999999;

                .describe-info {
                  span {
                    padding-right: 8px;
                    margin-right: 8px;
                    border-right: 2px solid rgba(0, 0, 0, 0.2);
                  }
                }

                .describe-like {
                  font-weight: 500;

                  span {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    margin-right: 6px;
                  }
                }
              }
            }

            .card-footer {
              margin-top: 28px;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .icon-title {
                margin-left: 10px;
                font-size: 15px;
                font-weight: 400;
                color: #666666;
              }

              .icon-text {
                background-image: url(~@/assets/矩形图标.png);
                width: 108px;
                height: 24px;
                text-align: center;
                color: #288add;
              }
            }
          }
        }
      }

      .elpagination {
        margin-bottom: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .elpagination-title {
          margin-right: 4px;
        }
      }

      .topCont {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        .list-title-cont {
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }

        .dateBtn {
          span {
            width: 17px;
            height: 17px;
            display: inline-block;
            margin-right: 5px;
          }
        }
      }

      .timeLineCont {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;

        .lineCont {
          position: absolute;
          top: 5px;
          height: 2px;
          background: #ebeef5;
        }

        .svgBg {
          width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: rgba(248, 248, 251, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 50%;
          transform: translateX(-27px);

          svg {
            transform: rotate(-26deg);
          }

          .triangle {
            fill: rgba(222, 222, 222, 1);
            stroke: rgba(222, 222, 222, 1);
            stroke-width: 100;
          }

          .triangle.active {
            fill: rgba(43, 193, 123, 1);
            stroke: rgba(43, 193, 123, 1);
          }
        }

        .timeText {
          text-align: center;
          height: 26px;
          font-size: 14px;
          font-weight: 500;
          line-height: 40px;
          // padding: 6px 0px;
          color: #666666;
        }

        .timeText.active {
          font-size: 16px;
          color: rgba(43, 193, 123, 1);
        }

        .list-li {
          .list-li-arrow-wrap {
            height: 24px;
            overflow: hidden;
            position: relative;
          }

          .list-li-arrow {
            width: 20px;
            height: 20px;
            background: #ffffff;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            border-radius: 3px;
            position: absolute;
            left: 50%;
            transform: rotate(45deg) translateX(-12px);
            margin-top: 24px;
          }

          .list-li-panel {
            width: 389px;
            min-height: 154px;
            max-height: 423px;
            border-radius: 3px;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
            overflow-y: auto;
          }

          .empty-cont {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .el-timeline-item {
            padding-bottom: 20px;
          }
        }
      }

      .playbackCont {
        margin-top: 40px;

        .list-title-cont {
          margin-bottom: 30px;
          font-size: 30px;
          font-weight: 500;
          color: #000000;
          height: 54px;
          line-height: 54px;

          span {
            color: rgba(43, 193, 123, 1);
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
            height: 54px;
          }
        }
      }

      .dialogTimeLine {
        min-width: 430px;
        padding: 30px 30px 30px 0px;

        .timelineTitle {
          font-size: 20px;
          font-weight: 400;
        }

        .timeline-cont {
          overflow-y: auto;
          min-height: 160px;
          max-height: 540px;
          margin-top: 16px;
        }

        .empty-cont {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .list-li-panel .list-li-select,
      .dialogTimeLine .list-li-select {
        display: flex;
        justify-content: space-between;

        .list-imgCont {
          min-width: 140px;
          height: 96px;
          border-radius: 5px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .list-cont {
          position: relative;
          padding-left: 10px;
          width: calc(100% - 130px);
          height: 96px;

          .list-title {
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            height: calc(100% - 30px);
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
        }

        .info-entrance {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          height: 30px;

          div {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            width: calc(100% - 80px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          }

          button {
            width: 58px;
            height: 22px;
            border: 1px solid #2bc17b;
            border-radius: 3px;
            font-size: 12px;
            font-weight: 400;
            color: #2bc17b;
            padding: 0px;
          }

          button:hover {
            background: rgba($color: #2bc17b, $alpha: 0.1);
          }
        }
      }

      .dialogbody {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .dialogCalendar {
          padding: 30px;
        }
      }
    }
  }
}
</style>
